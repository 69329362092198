<template>
  <div class="container-full-page">
    <div class="Header">
      <div class="header-content">
        <!-- Menú de cambio de idioma para pantallas grandes -->
        <div class="language-buttons">
          <!-- Botón para español -->
          <button
            :class="{'selected': selectLanguage === 'sp'}"
            @click="changeLanguage('sp')"
          >
            <img
              class="flag-image"
              src="@/assets/images/Flags_mex.png"
              alt="Bandera de México"
            />
          </button>

          <!-- Botón para inglés -->
          <button
            :class="{'selected': selectLanguage === 'en'}"
            @click="changeLanguage('en')"
          >
            <img
              class="flag-image"
              src="@/assets/images/Flags_usa.png"
              alt="Bandera de Estados Unidos"
            />
          </button>
        </div>

        <!-- Menú de cambio de idioma y menú principal para pantallas pequeñas -->
        <div class="d-lg-none">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div class="language-selector" v-bind="attrs" v-on="on">
                <img
                  v-if="selectLanguage == 'sp'"
                  class="flag-image"
                  src="@/assets/images/Flags_mex.png"
                  alt="Bandera de México"
                />
                <img
                  v-else
                  class="flag-image"
                  src="@/assets/images/Flags_usa.png"
                  alt="Bandera de Estados Unidos"
                />
                <v-icon>mdi-menu-down</v-icon>
              </div>
            </template>
            <language-component class="menu-language"></language-component>
          </v-menu>
          <span class="nav-divider"></span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="showDialog = true" icon v-bind="attrs" v-on="on">
                <v-icon large>mdi-menu</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </div>

      <!-- Botón "Empezar" -->
      <div class="account-section">
        <p class="Btn-text">{{ texts.login.account }}</p>
        <button class="Btn-Account">{{ texts.login.start }}</button>
      </div>
    </div>

    <div class="container-form-login">
      <div class="form-container">
        <div
          @click="home"
          class="content-image cursor-pointer"
        >
          <img
            contain
            style="max-width: 400px; width: 100%; object-fit: contain; margin: auto;"
            src="../../../assets/logos/Logotipo_Dos tintas.png"
            alt="Fresher-logo.png"
          >
        </div>
        <p class="text-title mon-bold mt-5">{{ texts.login.textTitle }}</p>
        <p class="text-subtitle">{{ texts.login.textSubtitle }}</p>
        <div class="mt-9">
          <v-text-field
            v-model="email"
            type="email"
            name="email"
            :label="texts.login.inputEmail.label"
            outlined
            color="#000000"
            class="global-inputs mon-regular"
            :placeholder="texts.login.inputEmail.placeholder"
            persistent-placeholder
            @keypress="preLogin"
          ></v-text-field>
          <v-text-field
            v-model="password"
            name="password"
            :label="texts.login.inputPassword.label"
            :append-icon="bShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="() => (bShowPassword = !bShowPassword)"
            :type="bShowPassword ? 'text' : 'password'"
            outlined
            color="#000000"
            class="global-inputs mon-regular mt-5"
            :placeholder="texts.login.inputPassword.placeholder"
            persistent-placeholder
            @keypress="preLogin"
          ></v-text-field>
        </div>
        <div class="center-horizontall mt-5">
          <v-btn
            @click="login"
            elevation="0"
            :loading="bLoading"
            class="button-login mon-bold"
          >{{ texts.login.buttonLogin }}</v-btn>
        </div>
        <div class="mt-7">
          <v-btn
            to="/password-recovery"
            class="link-forgot-password mon-regular"
            text
          >{{ texts.login.forgotPassword }}</v-btn>
        </div>
      </div>
    </div>

    <div class="footer-login">
      <p class="footer-text-left mon-regular">
        ©{{ new Date().getFullYear() }} FRESHER. {{ texts.login.rights }}
      </p>
      <p class="footer-text-right mon-regular">
        <a href="/terms" class="footer-link">{{ texts.login.termCond }}</a> | 
        <a href="/privacy" class="footer-link">{{ texts.login.privacy }}</a>
        <a href="/terms" class="footer-link">{{ texts.login.termsAndConditions }}</a> | 
        <a href="/privacy" class="footer-link">{{ texts.login.privacyNotice }}</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: {},
    },
  },
  name: "LoginFormLayout",
  data() {
    return {
      //VARIABLES
      email: "",
      password: "",
      bShowPassword: false,
      bLoading: false,
    };
  },
  methods: {
    home: function () {
      this.$router.push("/home").catch(() => {});
    },
    changeLanguage(lang) {
      this.$store.commit("toggle_language", lang);
    },
    preLogin: function ($event) {
      if ($event.code == "Enter") this.login();
    },
    login: function () {
      this.bLoading = true;

      const payload = {
        sEmail: this.email,
        sPassword: this.password,
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/auth/customer`, payload)
        .then((response) => {
          this.bLoading = false;
          this.$store.commit("setToken", response.data.sToken);
          this.$store.commit("setTypeUser", response.data.sTypeAccount);
          // this.$store.commit("setEmail", response.data.sEmail);
          this.$store.commit("setFullName", response.data.sFullname);
          this.$store.commit("setEnterpriseId", response.data.sEnterpriseId);
          this.$store.commit("setUserId", response.data.sUserId);
          this.$store.commit("setIsLogged", true);
          this.$store.commit("setManager", response.data.bManager);

          this.mixInfo(response.data.sMessage);
          this.$router.push("/dashboard").catch(() => {});
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
};
</script>

<style scoped>

.language-buttons {
  display: flex;
  gap: 10px;
}

.language-buttons button {
  border: none;
  background: none;
  cursor: pointer;
}

.language-buttons .selected {
  background-color: #B8C7D4;
}

.Header {
  justify-content: space-between;
  display: flex;
  align-items: center; 
  flex-direction: row;
  padding: 20px;
}

.header-content {
  display: flex;
  align-items: center;;
  width: 100%;
}

.language-selector {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.menu-language {
  background-color: #EDF3FF;
}

.flag-image {
  width: 50px;
  align-items: center;
  justify-content: center;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 7px;
  padding-bottom: 0px;
}

.account-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-left: auto;
}

.Btn-Account {
  border: 2px solid #0075B8;
  color: #0075B8;
  background-color: #fff;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.Btn-Account:hover {
  background-color: #0075B8;
  color: #fff;
}

.Btn-text {
  display: flex;
  justify-content: center;
  align-items: center; 
  width: 10vw;
  margin-right: 15px;
  margin-top: 15px;
}

.form-container {
  width: 30vw; 
  max-width: 389px; 
  height: 50vh; 
  max-height: 501px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.global-inputs,
.button-login {
  width: 25vw !important;
}

.container-full-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.container-form-login {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.footer-login {
  width: 100%;
  padding: 10px 20px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-text-left,
.footer-text-right {
  font-size: 12px;
  color: #283c4d;
}

.footer-link {
  color: #0049b7;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

.button-create-account {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 12px;
  opacity: 1;
  background-color: #ffffff !important;
  text-transform: initial;
  font-size: 14px;
  width: 100%;
}

.link-forgot-password {
  text-transform: initial;
  color: #0049b7;
  text-decoration: underline;
  letter-spacing: 0px;
}

.button-login {
  width: 100%;
  height: 40px !important;
  background: transparent linear-gradient(265deg, #0075B8 0%, #0075B8 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 14px;
  text-transform: initial;
}

.text-title {
  font-size: 30px;
  text-align: center;
  letter-spacing: 0px;
}

.text-subtitle {
  font-size: 15px;
  text-align: center;
  letter-spacing: 0px;
  font-weight: 100;
}

.center-horizontall {
  display: flex;
  justify-content: center;
}

.content-image {
  width: 260px;
  height: 45px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.content-login {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: -13%;
}

@media (max-width: 720px) {
  .form-container {
    width: 80vw;
    height: auto;
  }

  .text-title {
    font-size: 25px;
  }
}
</style>